<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <div v-if="tagLine" class="tag-line" v-html="tagLine" />
    <b-input-group
      class="input-group-merge"
      :class="state ? 'is-valid' : state === null ? '' : 'is-invalid'"
    >
      <b-form-textarea
        :id="id"
        v-model="formValue"
        :placeholder="placeholder"
        type="text"
        :rows="rows"
        :max-rows="maxRows"
        :state="state"
        :readonly="readonly"
        :disabled="disabled"
        @copy="$emit('copy', $event)"
        @click.right="$emit('click-right', $event)"
      />
    </b-input-group>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  props: {
    // Got value from v-model
    value: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    tagLine: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    rows: {
      type: [String, Number],
      default: 2,
    },
    maxRows: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: '',
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-line {
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
  margin-bottom: 0.75rem;
}
</style>
